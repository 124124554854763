import * as React from "react";
import {
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
// Or use Today
import InsertInvitation from '@material-ui/icons/InsertInvitation';
export const DayIcon = InsertInvitation;

export const DayList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="_id" />
      <TextField source="label" />
      <NumberField source="maxSlots" />
      <ReferenceField label="Week" source="weekId" reference="weeks">
        <TextField source="label" />
      </ReferenceField>
      <ReferenceArrayField label="Orders" reference="orders" source="orders">
        <SingleFieldList>
          <ChipField source="ig" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton basePath="/days" />
    </Datagrid>
  </List>
);

const DayTitle = ({ record }) => {
    return <span>Day {record ? `"${record.label}"` : ''}</span>;
};

export const DayEdit = (props) => (
  <Edit title={<DayTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="_id" />
      <TextInput source="label" />
      <NumberInput source="maxSlots" />
      <ReferenceInput label="Week" source="weekId" reference="weeks">
        <SelectInput optionText="label" />
      </ReferenceInput>
      <ReferenceArrayInput source="orders" reference="orders" allowEmpty>
        <SelectArrayInput optionText="ig" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const DayCreate = (props) => (
  <Create title="Create a Day" {...props}>
    <SimpleForm>
      <TextInput source="label" />
      <NumberInput source="maxSlots" />
      <ReferenceInput label="Week" source="weekId" reference="weeks">
        <SelectInput optionText="label" />
      </ReferenceInput>
      <ReferenceArrayInput source="orders" reference="orders" allowEmpty>
        <SelectArrayInput optionText="ig" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

import * as React from "react";
import {
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import CalendarToday from '@material-ui/icons/CalendarToday';
export const WeekIcon = CalendarToday;

export const WeekList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="_id" />
      <TextField source="label" />
      <TextField source="description" />
      <TextField source="price" />
      <BooleanField source="active" />
      <BooleanField source="current" />
      <NumberField source="maxSlots" />
      <NumberField source="launchTimestamp" />
      <ReferenceArrayField label="Orders" reference="orders" source="orders">
        <SingleFieldList>
          <ChipField source="ig" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton basePath="/weeks" />
    </Datagrid>
  </List>
);

const WeekTitle = ({ record }) => {
    return <span>Week {record ? `"${record.label}"` : ''}</span>;
};

export const WeekEdit = (props) => (
  <Edit title={<WeekTitle />} {...props} undoable={false}>
    <SimpleForm>
      <TextInput disabled source="_id" />
      <TextInput source="label" />
      <TextInput multiline source="description" />
      <TextInput multiline source="price" />
      <BooleanInput source="active" />
      <BooleanInput source="current" />
      <NumberInput source="maxSlots" />
      <NumberInput source="launchTimestamp" />
      <ReferenceArrayInput source="orders" reference="orders" allowEmpty>
        <SelectArrayInput optionText="ig" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const WeekCreate = (props) => (
  <Create title="Create a Week" {...props}>
    <SimpleForm>
      <TextInput source="label" />
      <TextInput multiline source="description" />
      <TextInput multiline source="price" />
      <BooleanInput source="active" />
      <BooleanInput source="current" />
      <NumberInput source="maxSlots" />
      <NumberInput source="launchTimestamp" />
      <ReferenceArrayInput source="orders" reference="orders" allowEmpty>
        <SelectArrayInput optionText="ig" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

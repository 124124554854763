import * as React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  Filter,
  List,
  NullableBooleanInput,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import HowToReg from '@material-ui/icons/HowToReg';
export const OrderIcon = HowToReg;

const OrdersFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Day" source="dayConfirmedId" reference="days" alwaysOn>
      <SelectInput optionText="label" />
    </ReferenceInput>
    <ReferenceInput label="Week" source="weekId" reference="weeks" alwaysOn>
      <SelectInput optionText="label" />
    </ReferenceInput>
    <BooleanInput source="waitlist" />
  </Filter>
);

export const OrderList = (props) => (
  <List {...props} filters={<OrdersFilter />}>
    <Datagrid>
      <TextField source="_id" />
      <TextField source="ig" />
      <TextField source="pickupName" />
      <NumberField source="pieCount" />
      <BooleanField source="carpool" />
      <TextField source="carpoolLocation" />
      <DateField source="orderReceived" />
      <TextField source="dayConfirmed.label" />
      <ReferenceField label="Confirmed Day" source="dayConfirmedId" reference="days">
        <TextField source="label" />
      </ReferenceField>
      <BooleanField source="waitlist" />
      <EditButton basePath="/orders" />
    </Datagrid>
  </List>
);

const OrderTitle = ({ record }) => {
    return <span>Order {record ? `"${record.ig}"` : ''}</span>;
};

export const OrderEdit = (props) => (
  <Edit title={<OrderTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="_id" />
      <TextInput source="ig" />
      <TextInput source="pickupName" />
      <NumberInput source="pieCount" />
      <BooleanInput source="carpool" />
      <TextInput source="carpoolLocation" />
      <DateTimeInput source="orderReceived" />
      <NullableBooleanInput source="waitlist" />
      <ReferenceInput label="Confirmed Day" source="dayConfirmedId" reference="days">
        <SelectInput optionText="label" />
      </ReferenceInput>
      <ReferenceInput label="Requested Day" source="daySelectionId" reference="days">
        <SelectInput optionText="label" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const OrderCreate = (props) => (
  <Create title="Create a Order" {...props}>
    <SimpleForm>
      <TextInput source="ig" />
      <TextInput source="pickupName" />
      <NumberInput source="pieCount" />
      <BooleanInput source="carpool" />
      <TextInput source="carpoolLocation" />
      <DateTimeInput source="orderReceived" />
      <NullableBooleanInput source="waitlist" />
      <ReferenceInput label="Confirmed Day" source="dayConfirmedId" reference="days">
        <SelectInput optionText="label" />
      </ReferenceInput>
      <ReferenceInput label="Requested Day" source="daySelectionId" reference="days">
        <SelectInput optionText="label" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

import feathers from '@feathersjs/client';
import auth from '@feathersjs/authentication-client';
import { Admin, Resource } from 'react-admin';
import { restClient } from 'ra-data-feathers';
import { OrderList, OrderEdit, OrderCreate, OrderIcon } from './resources/orders';
import { WeekList, WeekEdit, WeekCreate, WeekIcon } from './resources/weeks';
import { DayList, DayEdit, DayCreate, DayIcon } from './resources/days';

/**
 * Feathers rest connection
 */
const app = feathers();
const client = feathers.rest(process.env.REACT_APP_API_URL);

app.configure(client.fetch(window.fetch));
app.configure(auth());

// Feathers Services
// const orderService = app.service('orders');

const restClientOptions = {
  id: '_id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for updates
};

const authClient = {
  login: (params) => app.authenticate({
    strategy: 'local',
    email: params.username,
    password: params.password,
  }),
  logout: () => app.logout().then(() => Promise.resolve()),
  checkAuth: () => app
    .reAuthenticate()
    .then(() => Promise.resolve())
    .catch(() => Promise.reject({ redirectTo: '/login' })),
  checkError: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(),
}

const App = () => (
  <Admin
    title='ra-data-feathers Example'
    dataProvider={restClient(app, restClientOptions)}
    authProvider={authClient}
  >
    <Resource name="orders" list={OrderList} edit={OrderEdit} create={OrderCreate} icon={OrderIcon}/>
    <Resource name="weeks" list={WeekList} edit={WeekEdit} create={WeekCreate} icon={WeekIcon}/>
    <Resource name="days" list={DayList} edit={DayEdit} create={DayCreate} icon={DayIcon}/>
  </Admin>
);

export default App;
